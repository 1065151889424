import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    const { showGoBack, buttonColor, goback, logo, company } = this.props;
    const to = `/?token=${localStorage.getItem("TOKEN")}`;
    return (
      <Row style={{ marginBottom: "100px" }}>
        <Col>
          {showGoBack && (
            <FontAwesomeIcon
              color={buttonColor}
              icon="arrow-left"
              size="lg"
              onClick={goback}
            />
          )}
          <Link to={to}>
            <img
              src={logo}
              className="float-right"
              alt={company}
              style={{ width: "100px" }}
            />
          </Link>
        </Col>
      </Row>
    );
  }
}

export default withRouter(
  connect((state) => ({
    logo: state.customer.logo,
    company: state.customer.company,
    bonus: state.bonus,
    customer: state.customer,
  }))(Header)
);
