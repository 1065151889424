import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
class Footer extends Component {
    render() {
        const logo = 'https://d3ubqgxxzibvg.cloudfront.net/adm/assets/img/logo/vertical_amarelo_preto.png'
        return (
            <Row>
                <Col>
                    <div className="text-center">
                        <img src={logo} alt="CRM&Bônus" width={100} />
                    </div>
                </Col>
            </Row>
        )
    }
}

export default Footer