//const BASE_URL = 'http://crmbonus.local';
//const BASE_URL = 'https://homol.crmbonus.com';
const BASE_URL = "https://d3ubqgxxzibvg.cloudfront.net";

const INITIAL_STATE = {
  id: localStorage.getItem("ID") || "",
  company: localStorage.getItem("COMPANY") || "",
  text_restaurante: localStorage.getItem("TEXT_RESTAURANTE") || "",
  cor_layout: localStorage.getItem("COR_LAYOUT") || "#000",
  logo:
    `${BASE_URL}/files/logo/${localStorage.getItem("LOGO")}` || "",
  favicon:
    `${BASE_URL}/files/favicon/${localStorage.getItem(
      "FAVICON"
    )}` || "",
  customer_status_id: localStorage.getItem("CUSTOMER_STATUS_ID") || 1,
  associar_garcom: localStorage.getItem("ASSOCIAR_GARCOM") || false,
  idioma: localStorage.getItem("IDIOMA") || "por",
  mask_celular: localStorage.getItem("MASK_CELULAR") || "(00) 00000-0000",
  moeda: localStorage.getItem("MASK_CELULAR") || "R$",
  pais_celular: localStorage.getItem("PAIS_CELULAR") || "55",
  show_data_nasc: localStorage.getItem("SHOW_DATA_NASC") || false,
  show_email: localStorage.getItem("SHOW_EMAIL") || false,
  show_pesquisa_satisfacao:
    localStorage.getItem("SHOW_PESQUISA_SATISFACAO") || false,
  show_pesquisa_satisfacao2:
    localStorage.getItem("SHOW_PESQUISA_SATISFACAO2") || false,

  show_pesquisa_satisfacao3:
    localStorage.getItem("SHOW_PESQUISA_SATISFACAO3") || false,
  show_demografico: localStorage.getItem("SHOW_DEMOGRAFICO") || false,
  pergunta_pesquisa_satisfacao2:
    localStorage.getItem("PERGUNTA_PESQUISA_SATISFACAO2") || false,
  pergunta_pesquisa_satisfacao3:
    localStorage.getItem("PERGUNTA_PESQUISA_SATISFACAO3") || false,

  token: localStorage.getItem("TOKEN") || "",
  cod_linx: localStorage.getItem("COD_LINX") || "",
  campaigns: JSON.parse(localStorage.getItem("CAMPAIGNS")) || {},
  garcons: JSON.parse(localStorage.getItem("GARCONS")) || {},
};

export default function customer(state = INITIAL_STATE, action) {
  if (action.type === "UPDATE_CUSTOMER") {
    return {
      ...state,
      id: action.id,
      company: action.company,
      text_restaurante: action.text_restaurante,
      cor_layout: action.cor_layout,
      logo: `${BASE_URL}/files/logo/${action.logo}`,
      favicon: `${BASE_URL}/files/favicon/${action.favicon}`,
      customer_status_id: action.customer_status_id,
      text_end_generation_influencers: action.text_end_generation_influencers,
      associar_garcom: action.associar_garcom,
      idioma: action.idioma,
      mask_celular: action.mask_celular,
      moeda: action.moeda,
      color_background: action.color_background,
      color_text: action.color_text,
      color_form_text: action.color_form_text,
      color_btn: action.color_btn,
      color_btn_text_color: action.color_btn_text_color,
      pais_celular: action.pais_celular,
      show_data_nasc: action.show_data_nasc,
      show_email: action.show_email,
      show_pesquisa_satisfacao: action.show_pesquisa_satisfacao,
      show_pesquisa_satisfacao2: action.show_pesquisa_satisfacao2,
      show_pesquisa_satisfacao3: action.show_pesquisa_satisfacao3,
      show_demografico: action.show_demografico,
      pergunta_pesquisa_satisfacao2: action.pergunta_pesquisa_satisfacao2,
      pergunta_pesquisa_satisfacao3: action.pergunta_pesquisa_satisfacao3,
      token: btoa(action.token),
      cod_linx: btoa(action.cod_linx),
      campaigns: action.campaigns,
      garcons: action.garcons,
    };
  }
  return state;
}
